import React, {useEffect} from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { StaticImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import './styles.scss'
//Components
import SubNav from '@components/subNav/subNav.jsx'
import Hero from '@components/hero/hero.jsx'
import Portrait from '@components/portrait/portrait.jsx'
import Accordion from "@components/accordion/Accordion.jsx"

// markup
const ResourcesPage = ({ data }) => {
  const SaraPortrait = getImage(data.portraitSara)
  const HeroImage = getImage(data.hero)

  useEffect(() => {
      document.querySelector('body').addEventListener('click', (e) => {
        document.getElementById(e.target?.href?.split("#")[1])?.focus();
        }
      )
  }, []);

  return (
    <Layout addedClass="page--resources">
      <DocumentHeadMeta title='Tools &amp; Resources | ALL IN&reg; for Lupus Nephritis' description='Find ALL IN&reg; tools and resources to help you better manage lupus nephritis. Sign up to receive information about upcoming events, helpful tips, recipes, and more.' canonicalURL='https://www.allinforlupusnephritis.com/resources' />
      <SubNav>
        <AnchorLink to="/resources#ALL-IN-Awareness-Kit">ALL IN Awareness Kit</AnchorLink>
        <AnchorLink to="/resources#Self-Advocacy">Self-Advocacy</AnchorLink>
        <AnchorLink to="/resources#COVID-19">COVID-19</AnchorLink>
        <AnchorLink to="/resources#Access-Health-Video">Access Health Video</AnchorLink>
      </SubNav>
      <Hero heroImage={HeroImage} imgAlt={""}>
            <h1>Resources</h1>
            <p><strong>ALL IN&reg; offers important information and useful tools to help you better manage lupus nephritis. <Link to="/register">Sign up to receive information about upcoming events, helpful tips, recipes, and more.</Link></strong></p>
      </Hero>
      <section tabIndex="-1" id="ALL-IN-Awareness-Kit" className="bg-light-blue">
        <div className="container">
          <h2>The ALL IN Lupus Nephritis Awareness Kit</h2>
          <p>To help empower those living with or at risk of developing lupus nephritis, ALL IN has created the Lupus Nephritis Awareness Kit with insights from members of the community.</p>
          <p>Inside the kit you'll find information and tools to help increase your understanding of lupus nephritis along with tips to help you manage living with the disease. If you're interested in receiving a kit, click below.</p>
          <Link to="/register" className="cta--purple cta--body cta--margin-bottom">Sign up now</Link>
        </div>
      </section>
      <section tabIndex="-1" id="Self-Advocacy">
      <div className="container container--split checklist-row">
            <div className="split-block--50">
              <StaticImage src="../../images/resources/resource-guides.png" className="split-item__icon" quality="90" placeholder="NONE" alt="" width={459}></StaticImage>
            </div>
            <div className="split-block--50">
              <div className="checklist-row__list">
                <h3 className="title">Be Your Best Self-Advocate</h3>
                <p>These guides include suggested questions and discussion topics that could be helpful to review with your healthcare provider. There are two versions available, depending on where you are in your lupus nephritis journey. Be sure to bring one with you to your next appointment.</p>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" className="" quality="100" placeholder="NONE" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p><a target="_blank" href="/assets/pdfs/newly-diagnosed-with-lupus-nephritis-guide.pdf">Guide for people newly diagnosed with lupus nephritis</a></p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" className="" quality="100" placeholder="NONE" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p><a target="_blank" href="/assets/pdfs/living-with-lupus-nephritis-guide.pdf">Guide for people with established lupus nephritis</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <Portrait name="Sara" description="Diagnosed with lupus nephritis in 2004" imgSrc={SaraPortrait} imgAlt=""></Portrait>
          <p>"I have personally found that advocating for yourself is probably the most important part of the doctor/patient relationship. You have to go in armed and ready—not in a confrontational way, in a collaborative way."</p>
        </div>
      </section>
      <section className="bg-purple">
        <div className="container">
          <StaticImage src="../../images/home-hands-icon.png" className="home-hands-icon" quality="90" placeholder="NONE" alt="" width={116} ></StaticImage>
          <h2>Don't hesitate to ask for help</h2>
          <p>If you need help or support, consider reaching out to a family member, friend, or co-worker. Contact your doctor if you have any health-related questions. You may also want to consider looking into supportive counseling if needed.</p>
        </div>
      </section>
      <section tabIndex="-1" id="COVID-19" className="bg-light-blue">
        <div className="container container--narrow">
          <h2>Coping with COVID-19</h2>
          <p className="text-left">Many people are coping with feelings of uncertainty, anxiety, and loneliness due to the ongoing COVID-19 pandemic. For those living with lupus nephritis, concerns about health and wellness may be even greater.</p>
          <p className="text-left">ALL IN wants to help you feel less alone during this pandemic. Our hope is that our community can help you feel connected to others living with lupus nephritis. To support you during this time, we’d like to share with you some activities you may find helpful—not only in connecting with others but in caring for yourself.</p>
        </div>
      </section>
      <section className="bg-light-blue">
        <div className="container container--covid-19">
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/resources/icon-phone.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Socialize virtually</h3>
                  <p>Although we might not be able to socialize with others in person right now, phone calls, social media sites, and online meeting tools may help us feel connected with those we love while keeping ourselves and others safe. Consider joining the <a data-modal-id='exit-modal' href=" https://www.facebook.com/ALLINforLN/" className="link--purple">ALL IN for LN</a> community on Facebook to connect with others who are affected by lupus nephritis.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/resources/icon-hands.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Build a support network</h3>
                  <p>It's important to have people such as family, friends, and co-workers that you can turn to for support. Another option is joining an online support group. In an online community, you can talk about your feelings or concerns in a caring and supportive environment while listening to others share their own experiences. To hear about the experiences of other people living with lupus nephritis, watch our video series <Link to="/personal-stories" className="link--purple">here</Link>.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/resources/icon-yoga.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Participate in an activity you enjoy</h3>
                  <p>Living with lupus nephritis may feel overwhelming at times. Engaging in an activity you enjoy such as cooking, gardening, walking, or reading may help refocus your attention and provide a much-needed break during challenging times.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/resources/icon-salid.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Practice self-care</h3>
                  <p>Proper rest and nutrition, as well as appropriate physical activity, are important healthy habits for anyone, but especially for those living with lupus nephritis. To learn more about diet and exercise with lupus nephritis, <Link to="/managing-lupus-nephritis#Managing-Your-Health" className="link--purple">click here</Link>. Always talk with your physician before you start or make any changes to your diet and exercise plan.</p>
                </div>
            </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow">
          <h2>Sources of Information About COVID-19</h2>
          <p className="text-left">The following resources offer information about COVID-19 for people living with lupus nephritis and lupus. Please note that Aurinia does not endorse or monitor the activities and content of the following National Advocacy Groups or Government and Health Agencies. The following resources offer information about COVID-19 for people living with lupus nephritis and lupus.</p>
            <Accordion title="National Advocacy Groups" subTitle="Find helpful information about COVID-19 for people living with lupus and lupus nephritis.">
              <a data-modal-id='exit-modal' href="https://www.ladainc.org/news-and-events">Lupus and Allied Diseases Association—Coronavirus (COVID-19) and Lupus </a>
              <p className="item">Learn more about COVID-19 and special precautions for those at higher risk.</p>
              <a data-modal-id='exit-modal' href="https://www.lupus.org/coronavirus">Lupus Foundation of America—Coronavirus (COVID-19) and Lupus </a>
              <p className="item">Get news and updates on COVID-19 and resources that may help you with your emotional and physical health.</p>
              <a data-modal-id='exit-modal' href="https://www.lupusresearch.org/covid-19-updates/">Lupus Research Alliance - COVID-19 News </a>
              <p className="item">Relevant information, news, and frequently asked questions as it relates to COVID-19 and its effect on the lupus nephritis and lupus community.</p>
              <a data-modal-id='exit-modal' href="https://madetosave.org/">Made to Save</a>
              <p className="item">Made to Save is a national grassroots organization that provides the hardest-hit communities by the pandemic with access to COVID-19 vaccines and education.</p>
              <a data-modal-id='exit-modal' href="https://www.kidney.org/covid-19">National Kidney Foundation—Be Prepared: Kidney Patient Prep for Coronavirus </a>
              <p className="item">Learn how you can reduce your risk of exposure to COVID-19, discover kidney-healthy food items, and understand the importance of maintaining medical appointments.</p>
            </Accordion>

            <Accordion title="Local Advocacy Groups" subTitle="Learn about the impact of COVID-19 and find resources in your area.">
              <a data-modal-id='exit-modal' href="https://blackdoctorsconsortium.com/">Black Doctors COVID-19 Consortium </a>
              <p className="item">This education and advocacy group for African Americans provides mobile unit COVID-19 testing and vaccinations in the hardest-hit areas of Southeastern Pennsylvania.</p>
            </Accordion>

            <Accordion title="Government and Health Agencies" subTitle="Get the latest facts and updates on COVID-19 from all over the world.">
              <a data-modal-id='exit-modal' href="https://www.cdc.gov/coronavirus/2019-ncov/index.html">Centers for Disease Control—Coronavirus (COVID-19) </a>
              <p className="item">Learn ways to help protect yourself from COVID-19.</p>
              <a data-modal-id='exit-modal' href="https://www.dol.gov/coronavirus">U.S. Department of Labor—Coronavirus Resources </a>
              <p className="item">Get information about workplace safety, wages,&nbsp;hours, leave time, unemployment insurance, and support for workers in various industries.</p>
              <a data-modal-id='exit-modal' href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019">World Health Organization </a>
              <p className="item">Visit this site for the latest updates on COVID-19 including country-specific responses to the pandemic and global research and development efforts.</p>
            </Accordion>
        </div>
      </section>
      <section tabIndex="-1" id="Access-Health-Video" className="bg-light-blue">
        <div className="container container--split container--bring-ln-out">
          <div className="split-item bring-ln-out__text">
            <h2>Bringing lupus nephritis out of the shadows</h2>
            <p>ALL IN teamed up with Access Health on Lifetime to deliver a TV segment featuring Rheumatologist Dr. Alvin Wells, Nephrologist Dr. Dawn Caster, and lupus nephritis warrior and advocate Monique. Watch this video to learn more about lupus nephritis and the importance of routine urine testing.</p>
            <a data-modal-id='exit-modal' href="https://www.youtube.com/watch?v=AEE5dpQE6J0" className="cta--purple cta--body">Watch now</a>
          </div>
            <div className="split-item bring-ln-out__image">
              <a data-modal-id='exit-modal' href="https://www.youtube.com/watch?v=AEE5dpQE6J0"><StaticImage src="../../images/resources/bring-out-video-thumb.png" className="bring-ln-out__image-link" quality="90" placeholder="NONE" alt="Watch video by Access Health" width={614}></StaticImage></a>
            </div>
          
        </div>
      </section>
    </Layout>
  )
}

export default ResourcesPage

export const pageQuery = graphql`
  query ResourcesQuery {
    portraitSara: file(relativePath: {eq: "portrait-Sara.png"}) {
      childImageSharp {
        gatsbyImageData(height:240)
      }
    }
    hero: file(relativePath: {eq: "resources/hero-resources.png"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
  }
`
